<script setup lang="ts">
import type { TransferListOptionsItem } from '@reports/components/TransferList/types'

interface Props {
  options: TransferListOptionsItem[]
}

const props = withDefaults(defineProps<Props>(), {
  options: () => [],
})

defineEmits(['unselect'])

const sortedOptions = computed(() =>
  [...props.options].sort(
    (
      a: { label: string; value: string },
      b: { label: string; value: string },
    ) => {
      return a.label.localeCompare(b.label)
    },
  ),
)
</script>

<template>
  <ReportsTransferListBaseList :options="sortedOptions" variant="tertiary">
    <template #item="{ option }">
      <ReportsTransferListItem
        :option="option"
        variant="tertiary"
        :action="{ icon: 'trash', tooltip: 'Remove from Selected fields' }"
        action-class="text-red"
        @click-button="$emit('unselect', option)"
      />
    </template>
    <template #empty> Select the fields to export </template>
  </ReportsTransferListBaseList>
</template>
